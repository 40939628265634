'use client'

import React, { ComponentType, useState } from 'react'
import { TournamentSummary } from 'types'
import { Heading } from 'components/ui/text/Heading'
import LinkButton from 'components/router/LinkButton'
import Button from 'components/ui/basic/Button'
import {
  gamePath,
  searchPath,
  tournamentSchedulePath,
  tournamentTablePath
} from 'shared/urlHelpers'
import Table from 'components/table/Table'
import ScheduleListItem from 'components/ScheduleListItem'
import { isTabletScreen } from 'shared/domHelpers'
import Link from 'components/router/Link'
import { Search } from 'react-feather'

const SummaryDetail: ComponentType<{ summary: TournamentSummary }> = ({
  summary
}) => {
  const { currentRound, organization, table } = summary
  const tournamentHeadlineArr = [organization?.name, currentRound?.name].filter(
    (x) => !!x
  )

  let showedGames = currentRound?.games
  let tableLimit = 7
  if (currentRound?.games) showedGames = currentRound?.games.slice(0, 4)
  if (showedGames?.length === 3) tableLimit = 4
  else if (showedGames?.length === 2 || showedGames?.length === 1) {
    tableLimit = 2
  }

  const isTablet = isTabletScreen()

  const fadeOut = currentRound?.games || table

  return (
    <div className="view-vertical max-w-full mb-4">
      {tournamentHeadlineArr.length > 0 && (
        <Heading level={3} size="lg" className="w-full">
          {tournamentHeadlineArr.join(' - ')}
        </Heading>
      )}
      <div className={`view-horizontal ${fadeOut ? 'fade-bottom' : ''}`}>
        {currentRound?.games && (
          <div className="view-vertical w-full overflow-hidden pt-4 pr-4">
            {showedGames?.map((g) => (
              <Link key={g.id} href={gamePath(g)}>
                <ScheduleListItem game={g} showFullDate={true} />
              </Link>
            ))}
          </div>
        )}
        {table && !isTablet && (
          <div className="pl-4 border-l border-gray-300 w-full lg:w-0 xl:w-full h-auto lg:h-0 xl:h-auto visible lg:invisible xl:visible ">
            <Table table={table} limit={tableLimit} />
          </div>
        )}
        {!fadeOut && (
          <div className="bg-gray-100 w-full p-4 mb-4 rounded">
            Für diese Liga gibt es aktuell keine Spieldaten.
          </div>
        )}
      </div>
      {fadeOut && (
        <div className="text-center -mt-4 z-10 mb-4 btn-group justify-around">
          {currentRound?.games && (
            <LinkButton href={tournamentSchedulePath(summary)}>
              Spiele anzeigen
            </LinkButton>
          )}
          {table && (
            <LinkButton href={tournamentTablePath(summary)}>
              Tabelle anzeigen
            </LinkButton>
          )}
        </div>
      )}
    </div>
  )
}

export const CurrentGames: ComponentType<{
  tournamentsSummaries: TournamentSummary[]
}> = ({ tournamentsSummaries }) => {
  // TODO : properly catch if we have less than 3
  if (!tournamentsSummaries) return null

  const [activeSummary, setActiveSummary] = useState(tournamentsSummaries[0])
  return (
    <section
      id="currentGames"
      className="view-vertical flex-wrap container-white items-stretch"
    >
      <Heading level={2} size="lg" border className="w-full mb-4">
        Von der Kreisklasse bis zur Bundesliga
      </Heading>
      <div className="mb-4">
        <span>Von der </span>
        <Button
          onClick={() => setActiveSummary(tournamentsSummaries[0])}
          link
          className="sm:whitespace-nowrap"
        >
          {tournamentsSummaries[0].name}
        </Button>
        <span> über die </span>
        <Button
          onClick={() => setActiveSummary(tournamentsSummaries[1])}
          link
          className="sm:whitespace-nowrap"
        >
          {tournamentsSummaries[1].name}
        </Button>
        <span> bis zur </span>
        <Button
          onClick={() => setActiveSummary(tournamentsSummaries[2])}
          link
          className="sm:whitespace-nowrap"
        >
          {tournamentsSummaries[2].name}
        </Button>
        <span> und noch </span>
        <Link
          href={searchPath()}
          className="link link-icon whitespace-nowrap text-primary"
        >
          <span>vieles mehr</span>
          <Search />
        </Link>
      </div>
      <SummaryDetail summary={activeSummary} />
      <div className="view-horizontal space-x-2 justify-center w-100">
        {tournamentsSummaries.map((ts) => (
          <div
            key={ts.id}
            className={`h-1 w-16 cursor-pointer ${
              activeSummary.id === ts.id ? 'bg-primary' : 'bg-gray-400'
            }`}
            onClick={() => setActiveSummary(ts)}
          />
        ))}
      </div>
    </section>
  )
}
